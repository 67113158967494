.cursor {
  cursor: pointer;
}

@font-face {
  font-family: "Avenir";
  src: url("../font/AvenirLTProHeavy.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
.Avenir {
  font-family: "Avenir", sans-serif;
}

@keyframes zoom-in-zoom-out2 {
  0%, 100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
}
.playstore:hover .picon {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.appstore:hover .aicon {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.yellow-bg {
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../img/main3.svg");
  background-position: center;
  background-size: cover;
  padding: 30px;
}

.heart {
  position: fixed;
  bottom: 15px;
  right: 0px;
  z-index: 10000;
  width: 100%;
  font-size: 14px;
  background: transparent;
  text-align: center;
}

@keyframes heart {
  0%, 10%, 50%, 100% {
    transform: scale(1);
  }
  5%, 15% {
    transform: scale(1.1);
  }
}
.fullimg {
  display: none;
}

.mainBtn {
  top: 35px;
  right: 30px;
}

.cloud-bg {
  position: relative;
  display: block;
  left: 3%;
}

.cloud {
  animation: cloud 11s ease-in-out infinite;
}

@keyframes cloud {
  0%, 95%, 100% {
    transform: scale(0) translateY(380px);
    opacity: 0;
  }
  20%, 75% {
    transform: scale(0.97) translateY(0px);
    opacity: 1;
  }
  35%, 45%, 55%, 65%, 75% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  40%, 50%, 60%, 70% {
    transform: scale(0.97) translateY(0px);
    opacity: 1;
  }
  88%, 10% {
    opacity: 0;
  }
}
.icon2 {
  position: absolute;
  animation: icon2 11s ease-in-out infinite;
  width: 18%;
}

@keyframes icon2 {
  0%, 10%, 80%, 100% {
    opacity: 0.3;
    left: 190px;
    top: 370px;
    transform: rotate(-180deg) scale(0);
  }
  30%, 65% {
    opacity: 1;
    left: 50px;
    top: 20px;
    transform: rotate(0deg) scale(1);
  }
}
.icon-bg {
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 100%;
  width: 100%;
}

.face-bg {
  position: absolute;
  bottom: -30px;
  left: 15%;
}

.icon1 {
  width: 18%;
  position: absolute;
  animation: icon1 11s ease-in-out infinite;
}

@keyframes icon1 {
  0%, 10%, 80%, 100% {
    opacity: 0.5;
    left: 190px;
    bottom: 100px;
    transform: scale(0);
  }
  35%, 65% {
    opacity: 1;
    left: 190px;
    bottom: 160px;
    transform: scale(1);
  }
}
.icon3 {
  position: absolute;
  width: 18%;
  animation: icon3 11s ease-in-out infinite;
}

@keyframes icon3 {
  0%, 10%, 80%, 100% {
    opacity: 0.5;
    right: 210px;
    bottom: 80px;
    transform: rotate(-180deg) scale(0);
  }
  35%, 65% {
    opacity: 1;
    right: 90px;
    bottom: 230px;
    transform: rotate(0deg) scale(1);
  }
}
.icon4 {
  position: absolute;
  width: 18%;
  animation: icon4 11s ease-in-out infinite;
}

@keyframes icon4 {
  0%, 10%, 80%, 100% {
    opacity: 0.5;
    left: 160px;
    top: 340px;
    transform: rotate(180deg) scale(0);
  }
  35%, 65% {
    opacity: 1;
    left: 20px;
    top: 140px;
    transform: rotate(0deg) scale(1);
  }
}
.icon5 {
  position: absolute;
  width: 16%;
  animation: icon5 11s ease-in-out infinite;
}

@keyframes icon5 {
  0%, 10%, 80%, 100% {
    opacity: 0.5;
    right: 250px;
    top: 340px;
    transform: rotate(-180deg) scale(0);
  }
  35%, 65% {
    opacity: 1;
    right: 130px;
    top: 30px;
    transform: rotate(0deg) scale(1);
  }
}
.icon6 {
  position: absolute;
  width: 18%;
  animation: icon6 11s ease-in-out infinite;
}

@keyframes icon6 {
  0%, 10%, 80%, 100% {
    opacity: 0.5;
    right: 250px;
    top: 340px;
    transform: rotate(180deg) scale(0);
  }
  35%, 65% {
    opacity: 1;
    right: 250px;
    top: 70px;
    transform: rotate(0deg) scale(1);
  }
}
.full-page-background {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-image: url("../img//main-bg.png");
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Blur effect */
  border: none;
  font-size: 21px;
  z-index: 1001;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: white !important;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
  border: 1px solid #4F5153;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.9);
  color: black !important; /* Darker on hover */
}

.cover-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.full-cover-image {
  width: 100vw;
  height: 100vh;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  z-index: 1;
}

.img-div {
  width: 50%;
  height: 80%;
}

.img-div2 {
  width: 30%;
  height: 50%;
}

.img-div3 {
  width: 30%;
  height: 580px;
}

.share-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  background-color: #F9E238;
  border-radius: 100%;
  line-height: 40px;
  text-align: center;
}

.share-btn2 {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #F9E238;
  border-radius: 100%;
  line-height: 45px;
  text-align: center;
}

.pause-btn {
  position: absolute;
  bottom: 5px;
  width: 40px;
  height: 40px;
  background-color: #F9E238;
  border-radius: 100%;
  line-height: 40px;
  text-align: center;
}

.btnlol {
  animation: showImage 8s ease-in-out infinite;
}

@keyframes showImage {
  0%, 20% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(-100px);
    opacity: 1;
  }
  50% {
    transform: translateY(-150px);
    opacity: 0;
  }
  80% {
    transform: translateY(80px);
    opacity: 0;
  }
  95% {
    transform: translateY(0);
  }
}
.btntext {
  animation: showText 8s ease-in-out infinite;
}

@keyframes showText {
  0%, 20% {
    transform: translateY(0);
  }
  35%, 60% {
    transform: translateY(-70px);
    opacity: 1;
  }
  70% {
    transform: translateY(-140px);
    opacity: 0;
  }
  90% {
    transform: translateY(60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}
.btnimg {
  animation: showImg 8s ease-in-out infinite;
}

@keyframes showImg {
  0%, 30% {
    transform: translateY(0);
  }
  40%, 55% {
    transform: translateY(-40px);
    opacity: 1;
  }
  70%, 83% {
    transform: translateY(-118px);
    opacity: 1;
  }
  90% {
    transform: translateY(-160px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@media (max-width: 576px) and (min-width: 0px) {
  .face-bg {
    width: 60% !important;
    left: 18% !important;
  }
  .mainBtn {
    top: 20px;
    right: 25px;
  }
  .icon1 {
    width: 18%;
  }
  .img-div {
    width: 98%;
    height: 80%;
  }
  .img-div2 {
    width: 90%;
    height: 50%;
  }
  .img-div3 {
    width: 95%;
    height: 430px;
  }
}
@media (max-width: 699px) and (min-width: 600px) {
  @keyframes icon1 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 180px;
      bottom: 140px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 180px;
      bottom: 150px;
      transform: rotate(0deg) scale(1);
    }
  }
  .face-bg {
    width: 55%;
    left: 20%;
  }
}
@media (max-width: 575px) and (min-width: 500px) {
  @keyframes icon1 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 160px;
      bottom: 120px;
      transform: rotate(180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 160px;
      bottom: 130px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon2 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 190px;
      top: 330px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 50px;
      top: -20px;
      transform: rotate(0deg) scale(1);
    }
  }
  .face-bg {
    width: 50%;
    left: 25%;
  }
  @keyframes icon3 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      right: 210px;
      bottom: 80px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      right: 90px;
      bottom: 180px;
      transform: rotate(0deg) scale(1);
    }
  }
}
@media (max-width: 499px) and (min-width: 0px) {
  .title-text {
    font-size: 50px;
  }
  .title-text2 {
    font-size: 40px !important;
  }
  @keyframes cloud {
    0%, 95%, 100% {
      transform: scale(0) translateY(380px);
      opacity: 0;
    }
    20%, 75% {
      transform: scale(0.97) translateY(0px);
      opacity: 1;
    }
    35%, 45%, 55%, 65%, 75% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
    40%, 50%, 60%, 70% {
      transform: scale(0.97) translateY(0px);
      opacity: 1;
    }
    90%, 10% {
      opacity: 0;
    }
  }
  .icon1 {
    width: 15%;
  }
  @keyframes icon1 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 110px;
      bottom: 70px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 110px;
      bottom: 70px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon2 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 160px;
      top: 250px;
      transform: rotate(180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 50px;
      top: 20px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon3 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      right: 170px;
      bottom: 40px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      right: 50px;
      bottom: 120px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon4 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 160px;
      top: 250px;
      transform: rotate(180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 20px;
      top: 110px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon5 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      right: 150px;
      top: 250px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      right: 90px;
      top: 20px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon6 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      right: 140px;
      top: 250px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      right: 160px;
      top: 70px;
      transform: rotate(0deg) scale(1);
    }
  }
}
@media (max-width: 389px) and (min-width: 0px) {
  @keyframes icon1 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 80px;
      bottom: 50px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 80px;
      bottom: 50px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon2 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 120px;
      top: 150px;
      transform: rotate(180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 50px;
      top: 20px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon3 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      right: 140px;
      bottom: 40px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      right: 50px;
      bottom: 100px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon4 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      left: 120px;
      top: 150px;
      transform: rotate(180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      left: 20px;
      top: 80px;
      transform: rotate(0deg) scale(1);
    }
  }
  @keyframes icon6 {
    0%, 10%, 80%, 100% {
      opacity: 0.5;
      right: 140px;
      top: 150px;
      transform: rotate(-180deg) scale(0);
    }
    35%, 65% {
      opacity: 1;
      right: 120px;
      top: 70px;
      transform: rotate(0deg) scale(1);
    }
  }
}
@media (max-width: 1200px) and (min-width: 0px) {
  .cloud-bg {
    left: 5%;
  }
}
@media (min-width: 1300px) {
  .img-div {
    width: 30%;
    height: 80%;
  }
  .img-div2 {
    width: 30%;
    height: 60%;
  }
  .img-div3 {
    width: 30%;
    height: 580px;
  }
}
@media (max-width: 1080px) and (min-width: 575px) {
  .img-div {
    width: 50%;
    height: 80%;
  }
  .img-div2 {
    width: 50%;
    height: 50%;
  }
  .img-div3 {
    width: 50%;
    height: 480px;
  }
}
.title-bg {
  background-image: url("../img/title-bg.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center top;
  height: 350px;
}

@media (min-width: 1441px) {
  .title-bg {
    background-size: 2500px auto;
    background-position: center top;
    height: 650px;
  }
}
.title-text {
  font-size: 65px;
}

.title-text2 {
  font-size: 65px;
}

.BwGradual {
  font-family: "BwGradual", sans-serif;
}

@font-face {
  font-family: "BwGradual";
  src: url("../font/BwGradualDEMO-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
.BwGradual {
  font-family: "BwGradual", sans-serif;
}

@font-face {
  font-family: "BwGradualBold";
  src: url("../font/BwGradualDEMO-ExtraBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
.BwGradualBold {
  font-family: "BwGradualBold", sans-serif;
}/*# sourceMappingURL=style.css.map */